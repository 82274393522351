import { useGLTF } from '@react-three/drei'
import React from 'react'
import Mesh from './Mesh.jsx'

export default function Cupcake({ mouse }) {
  const { nodes, materials } = useGLTF('/models/cupcake.glb')

  return (
    <group position={[-1.755, -2.755, -3]} scale={0.3} rotation={[-0.5, 1, 0.88]}>
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.Sphere012} />
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.Sphere012_1} />
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.Sphere012_2} />
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.Sphere012_3} />
    </group>
  )
}

useGLTF.preload('/models/cupcake.glb')
