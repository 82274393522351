import React from 'react'
import { useTransform } from 'framer-motion'
import { motion } from 'framer-motion-3d'

export default function Mesh({ props, node, mouse, multiplier }) {
  const { castShadow, receiveShadow, geometry, material, position, rotation, scale } = node
  const rotationX = useTransform(mouse.x, [0, 1], [rotation.x - multiplier, rotation.x + multiplier])
  const rotationY = useTransform(mouse.y, [0, 1], [rotation.y - multiplier, rotation.y + multiplier])
  const positionY = useTransform(mouse.y, [0, 1], [position.y - 0.1, position.y + 0.1])
  const positionX = useTransform(mouse.x, [0, 1], [position.x - 0.1, position.x + 0.1])

  return (
    <motion.mesh
      {...props}
      castShadow={castShadow}
      receiveShadow={receiveShadow}
      geometry={geometry}
      material={material}
      position={position}
      rotation={rotation}
      scale={scale}
      rotation-x={rotationY}
      rotation-y={rotationX}
      position-y={positionY}
      position-x={positionX}
    >
      <meshStandardMaterial attach="material" color={'#0a0917'} />
    </motion.mesh>
  )
}
