import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import ContactScene from './ContactScene.jsx'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function Contact({ mouse }) {
  const firstRef = useRef(null)
  const secondRef = useRef(null)
  const letterSpacing = window.innerWidth <= 430 ? '0.50em' : window.innerWidth <= 1024 ? '1em' : '0.50em'
  const letterSpacing2 = window.innerWidth <= 430 ? '0.80em' : window.innerWidth <= 1024 ? '1em' : '1em'
  const now = new Date()

  const options = {
    timeZone: 'Europe/Paris',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  }

  const timeString = now.toLocaleTimeString('fr-FR', options)

  useEffect(() => {
    gsap.fromTo(
      firstRef.current,
      { letterSpacing: '0em' },
      {
        letterSpacing: letterSpacing,
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: firstRef.current,
          start: 'top 75%',
          end: 'bottom top',
          scrub: true,
        },
      }
    )

    gsap.fromTo(
      secondRef.current,
      { letterSpacing: '0em' },
      {
        letterSpacing: letterSpacing2,
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: secondRef.current,
          start: 'top 75%',
          end: 'bottom top',
          scrub: true,
        },
      }
    )
  }, [])

  return (
    <>
      <div className="contact__canvas-container">
        <ContactScene mouse={mouse} />
      </div>

      <div className="flex flex-col justify-between items-center gap-54 flex-1 py-12">
        <div className="z-[3] px-6">
          <div ref={firstRef} className="contact__title">Let's work</div>
          <div ref={secondRef} className="contact__title pt-1">Together</div>
        </div>

        <div className="flex flex-col items-center z-[3] gap-8">
          <div className="lg:hidden text-xs uppercase flex flex-row gap-1 items-center">
            Open to work
            <div className="relative flex h-3 w-3 mx-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#85E425] opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-[#85E425]"></span>
            </div>
          </div>
          <a target="_blank" href="mailto:pro@cynthiacaron.com" className="contact__button">Email me!</a>

          <div className="flex flex-col gap-2">
            <div className="lg:hidden flex flex-row gap-2 items-centers">
              <a target="_blank" href="https://www.linkedin.com/in/cynthiacrn/" className="footer-link-svg">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="footer-svg"
                >
                  <path
                    d="M7.02814 19.8291H3.44995V8.07215H7.13038V19.8291H7.02814ZM5.18793 6.43641C4.06336 6.43641 3.04102 5.5163 3.04102 4.28949C3.04102 3.16492 3.96112 2.14258 5.18793 2.14258C6.31251 2.14258 7.33485 3.06268 7.33485 4.28949C7.33485 5.5163 6.41474 6.43641 5.18793 6.43641ZM20.7275 19.8291H17.0471V14.104C17.0471 12.7749 17.0471 11.0369 15.2069 11.0369C13.2644 11.0369 13.06 12.4682 13.06 14.0017V19.8291H9.37953V8.07215H12.8555V9.7079C13.3667 8.78779 14.4912 7.86769 16.3314 7.86769C20.0119 7.86769 20.7275 10.3213 20.7275 13.4906V19.8291Z"
                    fill="#73777F"></path>
                </svg>
              </a>

              <a target="_blank" href="https://github.com/cynthiacrn" className="footer-link-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="#73777F"
                  className="footer-svg"
                >
                  <g clipPath="url(#clip0_215_120)">
                    <path d="M10 0C4.47833 0 0 4.4775 0 10C0 14.4183 2.865 18.1667 6.83917 19.4892C7.33833 19.5817 7.5 19.2717 7.5 19.0083V17.1467C4.71833 17.7517 4.13917 15.9667 4.13917 15.9667C3.68417 14.8108 3.02833 14.5033 3.02833 14.5033C2.12083 13.8825 3.0975 13.8958 3.0975 13.8958C4.10167 13.9658 4.63 14.9267 4.63 14.9267C5.52167 16.455 6.96917 16.0133 7.54 15.7575C7.62917 15.1117 7.88833 14.67 8.175 14.4208C5.95417 14.1667 3.61917 13.3092 3.61917 9.47833C3.61917 8.38583 4.01 7.49417 4.64917 6.79417C4.54583 6.54167 4.20333 5.52417 4.74667 4.1475C4.74667 4.1475 5.58667 3.87917 7.4975 5.1725C8.295 4.95083 9.15 4.84 10 4.83583C10.85 4.84 11.7058 4.95083 12.505 5.1725C14.4142 3.87917 15.2525 4.1475 15.2525 4.1475C15.7967 5.525 15.4542 6.5425 15.3508 6.79417C15.9925 7.49417 16.38 8.38666 16.38 9.47833C16.38 13.3192 14.0408 14.165 11.8142 14.4125C12.1725 14.7225 12.5 15.3308 12.5 16.2642V19.0083C12.5 19.2742 12.66 19.5867 13.1675 19.4883C17.1383 18.1642 20 14.4167 20 10C20 4.4775 15.5225 0 10 0Z" fill="#73777F"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_215_120">
                      <rect width="20" height="20" fill="#ECEADD80"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
            <Link to="credits" className="uppercase text-xs">
              Credits
            </Link>
          </div>
        </div>


        <section className="contact__marquee-container">
          <div className="contact__marquee-text-container">
            <div className="contact__marquee">
              <span className="contact__marquee-text">Based</span>
              <span className="contact__marquee-text">in</span>
              <span className="contact__marquee-text">france, </span>
              <span className="contact__marquee-text">available</span>
              <span className="contact__marquee-text">worldwide</span>
              <span className="contact__marquee-text text-4xl">•</span>
              <span className="contact__marquee-text">local</span>
              <span className="contact__marquee-text">time:</span>
              <span className="contact__marquee-text">{timeString}</span>
              <span className="contact__marquee-text text-4xl">•</span>
            </div>
          </div>
          <div className="contact__marquee-text-container">
            <div className="contact__marquee">
              <span className="contact__marquee-text">Based</span>
              <span className="contact__marquee-text">in</span>
              <span className="contact__marquee-text">france, </span>
              <span className="contact__marquee-text">available</span>
              <span className="contact__marquee-text">worldwide</span>
              <span className="contact__marquee-text text-4xl">•</span>
              <span className="contact__marquee-text">local</span>
              <span className="contact__marquee-text">time:</span>
              <span className="contact__marquee-text">{timeString}</span>
              <span className="contact__marquee-text text-4xl">•</span>
            </div>
          </div>
          <div className="contact__marquee-text-container">
            <div className="contact__marquee">
              <span className="contact__marquee-text">Based</span>
              <span className="contact__marquee-text">in</span>
              <span className="contact__marquee-text">france, </span>
              <span className="contact__marquee-text">available</span>
              <span className="contact__marquee-text">worldwide</span>
              <span className="contact__marquee-text text-4xl">•</span>
              <span className="contact__marquee-text">local</span>
              <span className="contact__marquee-text">time:</span>
              <span className="contact__marquee-text">{timeString}</span>
              <span className="contact__marquee-text text-4xl">•</span>
            </div>
          </div>
          <div className="contact__marquee-text-container">
            <div className="contact__marquee">
              <span className="contact__marquee-text">Based</span>
              <span className="contact__marquee-text">in</span>
              <span className="contact__marquee-text">france, </span>
              <span className="contact__marquee-text">available</span>
              <span className="contact__marquee-text">worldwide</span>
              <span className="contact__marquee-text text-4xl">•</span>
              <span className="contact__marquee-text">local</span>
              <span className="contact__marquee-text">time:</span>
              <span className="contact__marquee-text">{timeString}</span>
              <span className="contact__marquee-text text-4xl">•</span>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
