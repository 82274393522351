import { useEffect, useRef, useState } from 'react'
import { useLoading } from '../contexts/loader.jsx'
import { SoundProvider } from '../contexts/sound.jsx'
import MainHome from '../components/MainHome.jsx'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function Home() {
  const { isLoaded, setIsLoaded } = useLoading();
  const [currentCounter, setCurrentCounter] = useState(0)

  const loaderRef = useRef(null)

  useEffect(() => {
    const loadCounter = () => {
      let count = 0
      const incrementCounter = () => {
        const randomIncrement = Math.random() * 10
        count += Math.ceil(randomIncrement + (count / 10))
        if (count >= 100) {
          count = 100
          setCurrentCounter(count)
          setTimeout(() => {
            gsap.to(loaderRef.current, {
              opacity: 0,
              duration: 0.5,
              ease: 'power2.out',
              onComplete: () => {
                setIsLoaded(true)
              }
            })
          }, 1000)
          clearInterval(interval)
        } else {
          setCurrentCounter(count)
        }
      }
      const interval = setInterval(incrementCounter, 100)
    }

    loadCounter()
  }, [])

  if (isLoaded) {
    return (
      <SoundProvider>
        <MainHome />
      </SoundProvider>
    )
  }

  return (
    <div ref={loaderRef} className="loader__container">
      <div className="loader__counter">
        {currentCounter}
      </div>
    </div>
  )
}
