import { useEffect, useMemo, useState } from 'react'
import { useScramble } from 'use-scramble'

export default function ScramblingText({ words }) {
  const [wordIndex, setWordIndex] = useState(0)
  const currentWord = useMemo(() => words[wordIndex], [words, wordIndex])

  const { ref, replay } = useScramble({ text: currentWord })

  useEffect(() => {
    const intervalId = setInterval(() => {
      replay()

      setWordIndex((prevIndex) => (prevIndex + 1) % words.length)
    }, 2000)

    return () => clearInterval(intervalId)
  }, [replay, words])

  return <p ref={ref} />
}
