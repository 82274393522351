import { useGLTF } from '@react-three/drei'
import React from 'react'
import Mesh from './Mesh.jsx'

export default function Laptop({ mouse }) {
  const { nodes } = useGLTF('/models/laptop.glb')
  const position = window.innerWidth <= 768 ? [-0.5, 2.4, -2] : [-3.5, 2.4, -2]
  const scale = window.innerWidth <= 768 ? 0.03 : 0.05

  return (
    <group dispose={null}>
      <group scale={scale} position={position} rotation={[3, -1, 0.2]}>
        <Mesh multiplier={1.1} mouse={mouse} node={nodes.computer_0} />
      </group>
    </group>
  )
}

useGLTF.preload('/models/laptop.glb')
