import { useCallback, useEffect, useRef, useState } from 'react'
import { useMotionValue, useSpring } from 'framer-motion'
import HeroTitle from './HeroTitle.jsx'
import HeroScene from './HeroScene.jsx'
import ScrollSection from './ScrollSection.jsx'
import References from './References.jsx'
import Contact from './Contact.jsx'
import Footer from './Footer.jsx'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default function MainHome() {
  const heroRef = useRef(null)
  const [multiplier, setMultiplier] = useState(0)

  const mouse = {
    x: useMotionValue(0),
    y: useMotionValue(0),
  }

  const smoothMouse = {
    x: useSpring(mouse.x, { stiffness: 75, damping: 100, mass: 3 }),
    y: useSpring(mouse.y, { stiffness: 75, damping: 100, mass: 3 }),
  }

  const smoothElephantMouse = {
    x: useSpring(mouse.x),
    y: useSpring(mouse.y),
  }

  const manageMouseMove = useCallback((e) => {
    const { clientX, clientY } = e
    const { innerWidth, innerHeight } = window
    mouse.x.set(clientX / innerWidth)
    mouse.y.set(clientY / innerHeight)
    setMultiplier(0.4)
  }, [mouse.x, mouse.y])

  useEffect(() => {
    window.addEventListener('pointermove', manageMouseMove)
    return () => window.removeEventListener('pointermove', manageMouseMove)
  }, [manageMouseMove])

  useEffect(() => {
    ScrollTrigger.create({
      trigger: heroRef.current,
      start: 'top top',
      pin: true,
      pinSpacing: false,
    })

    gsap.fromTo(
      '.header__title-container',
      { scale: 0 },
      {
        scale: 1,
        duration: 3,
        ease: 'power2.out',
        delay: 1.2,
        onComplete: () => {
          gsap.to('.header__title-container', {
            scale: 0.47,
            y: '-3.5vw',
            ease: 'none',
            scrollTrigger: {
              trigger: '#about-me',
              start: 'top 100%',
              end: 'top 100px',
              scrub: 1,
            },
          })
        }
      }
    )

    gsap.to('.hero__text', {
      scale: 1.5,
      opacity: 0,
      duration: 10,
      scrollTrigger: {
        trigger: '#about-me',
        start: 'top 100%',
        end: 'top 100px',
        scrub: 1,
      },
    })

    gsap.fromTo(
      '.hero__mouse-scroll-container',
      { opacity: 0, scale: 0 },
      {
        opacity: 1,
        scale: 1,
        duration: 1.5,
        delay: 2.5,
        ease: 'power4.out',
      }
    )

    gsap.to('.hero__mouse-scroll-container', {
      scale: 0,
      opacity: 0,
      duration: 2,
      ease: 'power4.out',
      scrollTrigger: {
        trigger: '#about-me',
        start: 'top 100%',
        end: 'top 100px',
        scrub: true,
        onLeaveBack: () => gsap.to('.hero__mouse-scroll-container', { opacity: 1, duration: 1 }),
      },
    })

    gsap.to('header .header__title-container', {
      y: '-20vw',
      scrollTrigger: {
        trigger: '#references',
        start: 'top 100%',
        end: 'top 50%',
        scrub: 1,
        duration: 1,
      },
    })

    ScrollTrigger.create({
      trigger: '#references',
      start: 'bottom bottom',
      pin: true,
      pinSpacing: false,
      endTrigger: '#contact',
      end: 'top top',
    })

    setTimeout(ScrollTrigger.refresh, 1000)
  }, [])

  return (
    <>
      <header>
        <h1 className="header__title-container">
          <span className="header__title"></span>Cynthia <span className="header__subtitle">Caron</span>
        </h1>
      </header>

      <div className="body-container">
        <section id="home" className="hero__section" ref={heroRef}>
          <HeroTitle />
          <div className="hero__canvas-container">
            <HeroScene mouse={smoothElephantMouse} multiplier={multiplier} />
          </div>
          <div className="hero__mouse-scroll-container">
            <div className="p-1 w-fit bg-dark-eggshell-opacity rounded-full">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1" x="0px" y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
              >
                <g><path d="M67.71,42.631c-0.001-9.565-7.753-17.317-17.318-17.318h-0.784c-9.565,0.001-17.317,7.753-17.318,17.318V57.35   c0.001,9.565,7.753,17.316,17.318,17.317h0.784c9.565-0.001,17.317-7.753,17.318-17.317V42.631z M64.806,57.35   c-0.001,3.985-1.611,7.579-4.222,10.192c-2.613,2.611-6.208,4.221-10.193,4.222h-0.784c-3.985-0.001-7.58-1.611-10.193-4.222   c-2.611-2.613-4.221-6.208-4.222-10.192V42.631c0.001-3.985,1.611-7.58,4.222-10.193c2.613-2.611,6.208-4.221,10.193-4.222h0.784   c3.985,0.001,7.579,1.611,10.193,4.222c2.611,2.613,4.221,6.208,4.222,10.193V57.35z"/><path d="M49.977,39.903c-0.802,0-1.452,0.65-1.452,1.452v4.258c0,0.802,0.65,1.452,1.452,1.452c0.802,0,1.452-0.65,1.452-1.452   v-4.258C51.429,40.553,50.779,39.903,49.977,39.903z"/><path d="M49.093,94.682c0.53,0.424,1.283,0.424,1.814,0l7.258-5.806c0.626-0.501,0.727-1.414,0.227-2.04   c-0.501-0.626-1.414-0.727-2.04-0.227l0,0L50,91.689l-6.351-5.081c-0.626-0.501-1.54-0.399-2.04,0.227   c-0.501,0.626-0.399,1.539,0.227,2.04L49.093,94.682z"/><path d="M56.351,13.392c0.626,0.501,1.539,0.399,2.04-0.227c0.501-0.626,0.399-1.539-0.227-2.04l-7.258-5.807   c-0.531-0.425-1.283-0.425-1.814,0l-7.258,5.806c-0.626,0.501-0.728,1.414-0.227,2.04c0.501,0.626,1.414,0.727,2.04,0.227l0,0   L50,8.311L56.351,13.392z"/></g>
              </svg>
            </div>
            <div className="text-xs">Scroll</div>
          </div>
        </section>

        <ScrollSection mouse={smoothMouse} />

        <section id="references" className="references__section">
          <h3 className="references__title">In their words</h3>
          <References />
        </section>

        <section id="contact" className="contact__section">
          <Contact mouse={smoothMouse}/>
        </section>
      </div>

      <Footer />
    </>
  )
}
