import { useEffect, useRef } from 'react'
import About from './About.jsx'
import Stack from './Stack.jsx'
import ImageParticles from './ImageParticles.jsx'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function ScrollSection({ mouse }) {
  const containerRef = useRef(null)
  const skillsRef = useRef(null)

  useEffect(() => {
    const sections = gsap.utils.toArray('.scroll-section__wrapper-container section')

    ScrollTrigger.matchMedia({
      "all": function() {
        sections.forEach(section => {
          gsap.set(section, { height: 'auto', minHeight: '100dvh' })
        })

        gsap.to(sections, {
          xPercent: -100 * (sections.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: containerRef.current,
            pin: true,
            scrub: 1.5,
            snap: {
              snapTo: 1 / (sections.length - 1),
              directional: false,
              duration: 0.5,
              delay: 0.1,
              ease: 'power2.inOut'
            },
            end: () => {
              if (!containerRef.current?.offsetWidth) {
                return
              }
              return "+=" + containerRef.current.offsetWidth
            },
          }
        })
      }
    })
  }, [])

  return (
    <div className="scroll-section__wrapper" id="#about">
      <div ref={containerRef} className="scroll-section__wrapper-container">
        <section id="about-me" className="about-me__section">
          <h3 className="about__title">About me</h3>
          <About />
        </section>

        <section id="skills" ref={skillsRef}>
          <div className="skills__canvas-container">
            <ImageParticles mouse={mouse} containerRef={skillsRef}/>
          </div>
        </section>

        <section id="stack" className="stack__section">
          <Stack />
        </section>
      </div>
    </div>
  )
}
