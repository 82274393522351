import { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

function Card({ text, name, status, image, link }) {
  return (
    <div className="references__card-container">
      <div className="references__card-text">
        <div className="text-start text-eggshell italic lg:text-lg text-base">
          <span className="text-pastel-purple">"</span>
          {text}
          <span className="text-pastel-purple">"</span>
        </div>
        <div>
          <div className="text-start text-sm text-pastel-purple">
            {name}
          </div>
          <div className="text-start text-sm">
            {status}
          </div>
        </div>
      </div>
      <div className="references__card-image">
        <a target="_blank" href={link} className="linkedin-link h-2/4 w-2/4">
          <img
            className="rounded-full"
            src={image}
            alt={name}
          />
        </a>
      </div>
    </div>
  )
}

export default function References() {
  useEffect(() => {
    gsap.utils.toArray('.references__card-container').forEach((card) => {
      const image = card.querySelector('.references__card-image')
      const text = card.querySelector('.references__card-text')

      gsap.fromTo(
        image,
        { y: 50, opacity: 0 },
        {
          y: -25,
          opacity: 1,
          duration: 1.5,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: card,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          },
        }
      )

      gsap.fromTo(
        text,
        { y: -50, opacity: 0 },
        {
          y: 25,
          opacity: 1,
          duration: 1.5,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: card,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          },
        }
      )
    })
  }, [])

  return (
    <div className="references__container">
      <Card
        text="It was a pleasure to work with Cynthia, whose attention to detail and seamless communication with the design team are essential qualities for achieving the best possible outcome."
        name="Jonathan Kleinpeter"
        status="Design lead"
        image="images/jonathan-kleinpeter.JPG"
        link="https://www.linkedin.com/in/jonathan-kleinpeter-40a41169/"
      />

      <Card
        text="Cynthia joined us as a junior developer apprentice and quickly demonstrated a certain talent, which led her to become a Full Stack Developer on a permanent contract, with a strong expertise in front-end development. Her ability to balance remote training on OpenClassrooms while successfully carrying out her tasks at Warmango showed us that Cynthia is dedicated, meticulous, and exhibits great professionalism. Beyond her technical skills, she brings a genuine energy to our Tech team"
        name="Lucas Maisse"
        status="Ex-COO & Co-founder"
        image="images/lucas-maisse.JPG"
        link="https://www.linkedin.com/in/lucas-maisse-4026a0128/"
      />

      <Card
        text="Cynthia is a talented web developer with exceptional coding skills and a strong eye for design. She consistently delivers top-notch work and is a pleasure to work with."
        name="Antoine Audrain"
        status="Full Stack Developer & Product Strategist"
        image="images/antoine-audrain.JPG"
        link="https://www.linkedin.com/in/antoine-audrain/"
      />
    </div>
  )
}
