import { useGLTF } from '@react-three/drei'
import React from 'react'
import Mesh from './Mesh.jsx'

export default function Coconut({ mouse }) {
  const { nodes } = useGLTF('/models/coconut.glb')
  const position = window.innerWidth <= 768 ? [1.2, 0.9, 4] : [2.6, 1.1, 4]
  const scale = window.innerWidth <= 768 ? 0.4 : 0.5

  return (
    <group rotation={[0.8 , 0, 0.4]} scale={scale} position={position}>
      <Mesh node={nodes.Object_4} mouse={mouse} multiplier={1.2} />
      <Mesh node={nodes.Object_6} mouse={mouse} multiplier={1.2} />
    </group>
  )
}

useGLTF.preload('/models/coconut.glb')
