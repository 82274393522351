import { Link } from 'react-router-dom'

export default function Credits() {
  return (
    <div className="flex flex-col gap-8 lg:gap-24 p-8 h-dvh">
      <div className="flex flex-col gap-8">
        <Link to="/" className="uppercase text-sm text-start flex flex-row gap-2 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
               stroke="currentColor" className="h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
          </svg>
          back
        </Link>
        <div className="uppercase text-2xl">Credits</div>
      </div>

      <div className="flex flex-col items-start gap-4 text-sm">
        <div className="text-start">
          "Lowpoly 65% Mechanical Keyboard" <a target="_blank" href="https://skfb.ly/6Z9MU" className="text-pastel-purple underline">(https://skfb.ly/6Z9MU)</a> by sleepyjoshua is licensed under Creative Commons Attribution <a target="_blank" href="http://creativecommons.org/licenses/by/4.0/" className="text-pastel-purple underline">(http://creativecommons.org/licenses/by/4.0/)</a>.
        </div>
        <div className="text-start">
          "Elephant head low poly stylized" <a target="_blank" href="https://skfb.ly/oJX6y" className="text-pastel-purple underline">(https://skfb.ly/oJX6y)</a> by doublesob is licensed under Creative Commons Attribution-NonCommercial <a target="_blank" href="http://creativecommons.org/licenses/by-nc/4.0/" className="text-pastel-purple underline">(http://creativecommons.org/licenses/by-nc/4.0/)</a>.
        </div>
        <div className="text-start">
          "Coconut" <a target="_blank" href="https://skfb.ly/oynMx" className="text-pastel-purple underline">(https://skfb.ly/oynMx)</a> by No~ is licensed under Creative Commons Attribution <a target="_blank" href="http://creativecommons.org/licenses/by/4.0/" className="text-pastel-purple underline">(http://creativecommons.org/licenses/by/4.0/)</a>.
        </div>
        <div className="text-start">
          "Headphones" <a target="_blank" href="https://skfb.ly/6RJvZ" className="text-pastel-purple underline">(https://skfb.ly/6RJvZ)</a> by Eva_Sh is licensed under Creative Commons Attribution <a target="_blank" href="http://creativecommons.org/licenses/by/4.0/" className="text-pastel-purple underline">(http://creativecommons.org/licenses/by/4.0/)</a>.
        </div>
        <div className="text-start">
          "Laptop" <a target="_blank" href="https://skfb.ly/WruO" className="text-pastel-purple underline">(https://skfb.ly/WruO)</a> by codyseibert is licensed under Creative Commons Attribution <a target="_blank" href="http://creativecommons.org/licenses/by/4.0/" className="text-pastel-purple underline">(http://creativecommons.org/licenses/by/4.0/)</a>.
        </div>
        <div className="text-start">
          "Ramen Bowl from Ponyo" <a target="_blank" href="https://skfb.ly/6X7zt" className="text-pastel-purple underline">(https://skfb.ly/6X7zt)</a> by kaosigh is licensed under CC Attribution-NonCommercial-ShareAlike <a target="_blank" href="http://creativecommons.org/licenses/by-nc-sa/4.0/" className="text-pastel-purple underline">(http://creativecommons.org/licenses/by-nc-sa/4.0/)</a>.
        </div>
        <div className="text-start">
          "Coffee Cup [Low Poly] - by smakologg" <a target="_blank" href="https://skfb.ly/oVxCK" className="text-pastel-purple underline">(https://skfb.ly/oVxCK)</a> by smakologg is licensed under Creative Commons Attribution <a target="_blank" href="http://creativecommons.org/licenses/by/4.0/" className="text-pastel-purple underline">(http://creativecommons.org/licenses/by/4.0/)</a>.
        </div>
        <div className="text-start">
          "Peace of Mind" Kevin MacLeod <a target="_blank" href="https://incompetech.com/" className="text-pastel-purple underline">(https://incompetech.com/)</a> Licensed under Creative Commons: By Attribution 4.0 License <a target="_blank" href="http://creativecommons.org/licenses/by/4.0/" className="text-pastel-purple underline">(http://creativecommons.org/licenses/by/4.0/)</a>
        </div>
      </div>
    </div>

  )
}

