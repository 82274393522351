import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './pages/Home.jsx'
import Credits from './pages/Credits.jsx'
import MouseParticles from 'react-mouse-particles'
import { LoadingProvider } from './contexts/loader.jsx'

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "credits",
      element: <Credits />,
    },
  ])

  return (
    <div className="min-h-dvh">
      <MouseParticles
        g={0.3}
        num={9}
        radius={6}
        life={0.8}
        v={0.8}
        color={["#9A7CD8", "#DBE4E6", "#0a0917"]}
        alpha={0.7}
        level={2}
        cull="footer-socials,footer-availability,contact__button,linkedin-link,contact__contact-button,start__button"
      />

      <LoadingProvider>
        <RouterProvider router={router} />
      </LoadingProvider>
    </div>
  )
}
