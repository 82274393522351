import React, {useEffect, useRef} from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment, Float } from '@react-three/drei'
import CoffeeCup from './meshes/CoffeeCup.jsx'
import Keyboard from './meshes/Keyboard.jsx'
import Cupcake from './meshes/Cupcake.jsx'
import Headphones from './meshes/Headphones.jsx'
import Coconut from './meshes/Coconut.jsx'
import Ramen from './meshes/Ramen.jsx'
import Laptop from './meshes/Laptop.jsx'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

function Scene({ mouse }) {
  const groupRef = useRef()

  useEffect(() => {
    gsap.fromTo(groupRef.current.scale,
      { x: 0, y: 0, z: 0 },
      {
        x: 1,
        y: 1,
        z: 1,
        duration: 4,
        scrollTrigger: {
          trigger: "#contact",
          start: "top 50%",
          end: "top 10%",
          scrub: 1,
        }
      }
    );

    gsap.fromTo(groupRef.current.rotation,
      { y: 0 },
      {
        y: Math.PI * 4,
        duration: 4,
        scrollTrigger: {
          trigger: "#contact",
          start: "top 50%",
          end: "top 10%",
          scrub: 1,
        }
      }
    )
  }, [])

  return (
    <group ref={groupRef} scale={[0, 0, 0]}>
      <Float
        speed={0.8}
        rotationIntensity={0.5}
        floatIntensity={0.5}
        floatingRange={[0, 0.5]}
      >
        <group>
          <CoffeeCup mouse={mouse} />
          <Keyboard mouse={mouse} />
          <Cupcake mouse={mouse} />
          <Headphones mouse={mouse} />
          <Coconut mouse={mouse} />
          <Ramen en mouse={mouse} />
          <Laptop mouse={mouse} />
        </group>
      </Float>
    </group>
  )
}

export default function ContactScene({ mouse }) {

  return (
    <Canvas
      camera={{ position: [0, 0, 10], fov: 45 }}
      dpr={window.devicePixelRatio}
    >
      <Scene mouse={mouse} />
      <Environment preset="studio" />
    </Canvas>
  )
}
