import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

const SoundContext = createContext();

export const useSound = () => useContext(SoundContext);

export const SoundProvider = ({ children }) => {
  const audioRef = useRef(null);
  const [soundActivated, setSoundActivated] = useState(false);
  const [started, setStarted] = useState(false);
  const soundStatus = useMemo(() => soundActivated ? 'active' : 'inactive', [soundActivated])

  useEffect(() => {
    if (!audioRef.current) return
    audioRef.current.volume = 0.1
  }, [audioRef.current])

  const turnOnSound = async () => {
    if (!audioRef.current) return
    try {
      await audioRef.current.play()
      setSoundActivated(true)
    } catch (error) {
      console.error(error)
    }
  }

  const turnOffSound = async () => {
    if (!audioRef.current) return
    try {
      await audioRef.current.pause()
      setSoundActivated(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleStart = async () => {
    turnOnSound().then(() => setStarted(true))
  };

  return (
    <SoundContext.Provider value={{ soundStatus, turnOnSound, turnOffSound }}>
      <audio ref={audioRef} loop>
        <source src="/audio/peace_of_mind.mp3" type="audio/mpeg" />
      </audio>
      {started ? children : (
        <div className="h-screen flex justify-center items-center">
          <button className="start__button" onClick={handleStart}>Here We Go!</button>
        </div>
      )}
    </SoundContext.Provider>
  );
};
