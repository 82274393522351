import { useRef } from 'react'
import { useScroll, motion, useTransform } from 'framer-motion'

function Character({ children, progress, range }) {
  const opacity = useTransform(progress, range, [0, 1])
  return (
    <span>
      <span className="absolute opacity-[0.3]">{children}</span>
      <motion.span style={{opacity: opacity}}>
        {children}
      </motion.span>
    </span>
  )
}
function Word({ children, range, progress, highlightWords }) {
  const characters = children.split("")
  const amount = range[1] - range[0]
  const step = amount / children.length
  const isHighlighted = highlightWords.includes(children)

  return (
    <span className={`mr-4 relative ${isHighlighted ? 'text-pastel-purple' : ''}`}>
      {characters.map((character, i) => {
        const start = range[0] + (step * i)
        const end = range[0] + ((i + 1) * step)
        return <Character key={i} range={[start, end]} progress={progress}>{character}</Character>
      })}
    </span>

)}

function Paragraph({ highlightWords }) {
  const paragraph = "Full-stack developer, specializing in front-end development. Passionate about interactive and unique experiences. Adaptable and eager to learn from diverse teams."
  const element =  useRef(null)
  const { scrollYProgress } = useScroll({
    target: element,
    offset: ['start 0.8', 'start 0.5']
  })
  const words = paragraph.split(" ")

  return (
    <p
      className="about-me__text"
      ref={element}
    >
      {
        words.map( (word, i) => {
          const start = i / words.length
          const end = start + (1 / words.length)
          return <Word key={i} range={[start, end]} progress={scrollYProgress} highlightWords={highlightWords}>{word}</Word>
        } )
      }
    </p>
  )
}

export default function About() {
  const highlightWords = ['interactive', 'unique', 'experiences.']
  return (
    <Paragraph highlightWords={highlightWords}/>
  )
}
