export default function Stack() {
  return (
    <div className="stack__container">
      <h3 className="stack__text">
        <span className="stack__text-front" >ReactJS</span>
        <span className="stack__text-hidden">VueJS</span>
      </h3>
      <h3 className="stack__text">
        <span className="stack__text-front">Ruby On Rails</span>
        <span className="stack__text-hidden">Stimulus</span>
      </h3>
      <h3 className="stack__text">
        <span className="stack__text-front">ThreeJS</span>
        <span className="stack__text-hidden">Blender</span>
      </h3>
      <h3 className="stack__text">
        <span className="stack__text-front">React Three Fiber</span>
        <span className="stack__text-hidden">Gsap</span>
      </h3>
      <h3 className="stack__text">
        <span className="stack__text-front">Tailwind</span>
        <span className="stack__text-hidden">Figma</span>
      </h3>
      <h3 className="stack__text">
        <span className="stack__text-front">PostgreSQL</span>
        <span className="stack__text-hidden">WebGL</span>
      </h3>
    </div>
  )
}
