import { useEffect } from 'react'
import SplitType from 'split-type'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function HeroTitle() {
  useEffect(() => {
    const textBehind = new SplitType('p.hero__text-behind', { types: 'chars' })
    const textBlur = new SplitType('p.hero__text-behind-blur', { types: 'chars' })
    const textFront = new SplitType('p.hero__text-front', { types: 'chars' })
    const textBehindChars = textBehind.chars
    const textBlurChars = textBlur.chars
    const textFrontChars = textFront.chars

    gsap.fromTo(
      '#hero__container',
      {
        opacity: 0
      },
      {
        opacity: 1,
      }
    )

    gsap.fromTo(
      textBehindChars,
      {
        y: 100,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        delay: 0.5,
        ease: 'power4.out',
      }
    )

    gsap.fromTo(
      textBlurChars,
      {
        y: 100,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        delay: 0.5,
        ease: 'power4.out',
      }
    )

    gsap.fromTo(
      textFrontChars,
      {
        y: 100,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        delay: 0.5,
        ease: 'power4.out',
      }
    )
  }, [])

  return (
    <div className="hero__container" id="hero__container">
      <p className="hero__text-behind hero__text">experience<br/>maker</p>
      <p className="hero__text-behind-blur hero__text">experience <br/>maker</p>
      <p className="hero__text-front hero__text">experience <br/>maker</p>
    </div>
  )
}
