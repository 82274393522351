uniform vec2 uResolution;
uniform sampler2D uPictureTexture;
uniform sampler2D uDisplacementTexture;
uniform float uTime;

attribute float aIntensity;
attribute float aAngle;
attribute vec3 aInitialPosition;

varying vec3 vColor;

void main() {
  vec3 finalPosition = position;
  
  float animationFactor = smoothstep(0.0, 1.0, uTime * 2.0);
  vec3 newPosition = mix(aInitialPosition, finalPosition, animationFactor);
  
  float displacementIntensity = texture(uDisplacementTexture, uv).r;
  displacementIntensity = smoothstep(0.1, 0.3, displacementIntensity);
  
  vec3 displacement = vec3(
    cos(aAngle) * 0.2,
    sin(aAngle) * 0.2,
    1.0
  );
  displacement = normalize(displacement);
  displacement *= displacementIntensity;
  displacement *= 3.0;
  displacement *= aIntensity;
  
  newPosition += displacement;
  
  vec4 modelPosition = modelMatrix * vec4(newPosition, 1.0);
  vec4 viewPosition = viewMatrix * modelPosition;
  vec4 projectedPosition = projectionMatrix * viewPosition;
  gl_Position = projectedPosition;
  
  float pictureIntensity = texture(uPictureTexture, uv).r;
  gl_PointSize = 0.15 * pictureIntensity * uResolution.y;
  gl_PointSize *= (1.0 / -viewPosition.z);
  
  vec3 baseColor = vec3(0.6039, 0.4863, 0.8471);
  vColor = baseColor * pow(pictureIntensity, 2.0);
}
