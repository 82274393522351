import React, { useRef } from 'react'
import MagneticAnimation from './ui/MagneticAnimation.jsx'
import { useSound } from '../contexts/sound'
import { gsap } from 'gsap'

export default function Footer() {
  const availabilityRef = useRef(null)
  const contactRef = useRef(null)
  const { soundStatus, turnOnSound, turnOffSound } = useSound()

  const handleMouseEnter = () => {
    gsap.to(availabilityRef.current, { opacity: 0, onComplete: () => {
        gsap.set(availabilityRef.current, { display: 'none' })
        gsap.set(contactRef.current, { display: 'flex' })
        gsap.to(contactRef.current, { opacity: 1 })
      }})
  }

  const handleMouseLeave = () => {
    gsap.to(contactRef.current, { opacity: 0, onComplete: () => {
        gsap.set(contactRef.current, { display: 'none' })
        gsap.set(availabilityRef.current, { display: 'flex' })
        gsap.to(availabilityRef.current, { opacity: 1 })
      }})
  }

  return (
    <>
      <div className="footer__container">
        <div className="footer__left" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div ref={availabilityRef} className="footer__availability">
            Open to work
            <div className="relative flex h-3 w-3 mx-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#85E425] opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-[#85E425]"></span>
            </div>
          </div>
          <a
            ref={contactRef}
            target="_blank"
            href="mailto:pro@cynthiacaron.com"
            className="footer__contact-button"
          >
            Contact me!
          </a>
        </div>

        <div className="footer__socials">
          <MagneticAnimation>
            <a target="_blank" href="https://www.linkedin.com/in/cynthiacrn/" className="footer-link-svg">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="footer-svg"
              >
                <path
                  d="M7.02814 19.8291H3.44995V8.07215H7.13038V19.8291H7.02814ZM5.18793 6.43641C4.06336 6.43641 3.04102 5.5163 3.04102 4.28949C3.04102 3.16492 3.96112 2.14258 5.18793 2.14258C6.31251 2.14258 7.33485 3.06268 7.33485 4.28949C7.33485 5.5163 6.41474 6.43641 5.18793 6.43641ZM20.7275 19.8291H17.0471V14.104C17.0471 12.7749 17.0471 11.0369 15.2069 11.0369C13.2644 11.0369 13.06 12.4682 13.06 14.0017V19.8291H9.37953V8.07215H12.8555V9.7079C13.3667 8.78779 14.4912 7.86769 16.3314 7.86769C20.0119 7.86769 20.7275 10.3213 20.7275 13.4906V19.8291Z"
                  fill="#DBE4E680"></path>
              </svg>
            </a>
          </MagneticAnimation>

          <MagneticAnimation>
            <a target="_blank" href="https://github.com/cynthiacrn" className="footer-link-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="#DBE4E680"
                className="footer-svg"
              >
                <g clipPath="url(#clip0_215_120)">
                  <path d="M10 0C4.47833 0 0 4.4775 0 10C0 14.4183 2.865 18.1667 6.83917 19.4892C7.33833 19.5817 7.5 19.2717 7.5 19.0083V17.1467C4.71833 17.7517 4.13917 15.9667 4.13917 15.9667C3.68417 14.8108 3.02833 14.5033 3.02833 14.5033C2.12083 13.8825 3.0975 13.8958 3.0975 13.8958C4.10167 13.9658 4.63 14.9267 4.63 14.9267C5.52167 16.455 6.96917 16.0133 7.54 15.7575C7.62917 15.1117 7.88833 14.67 8.175 14.4208C5.95417 14.1667 3.61917 13.3092 3.61917 9.47833C3.61917 8.38583 4.01 7.49417 4.64917 6.79417C4.54583 6.54167 4.20333 5.52417 4.74667 4.1475C4.74667 4.1475 5.58667 3.87917 7.4975 5.1725C8.295 4.95083 9.15 4.84 10 4.83583C10.85 4.84 11.7058 4.95083 12.505 5.1725C14.4142 3.87917 15.2525 4.1475 15.2525 4.1475C15.7967 5.525 15.4542 6.5425 15.3508 6.79417C15.9925 7.49417 16.38 8.38666 16.38 9.47833C16.38 13.3192 14.0408 14.165 11.8142 14.4125C12.1725 14.7225 12.5 15.3308 12.5 16.2642V19.0083C12.5 19.2742 12.66 19.5867 13.1675 19.4883C17.1383 18.1642 20 14.4167 20 10C20 4.4775 15.5225 0 10 0Z" fill="#ECEADD80"/>
                </g>
                <defs>
                  <clipPath id="clip0_215_120">
                    <rect width="20" height="20" fill="#ECEADD80"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
          </MagneticAnimation>

          <MagneticAnimation>
            <div onClick={() => soundStatus === 'active' ? turnOffSound() : turnOnSound() } className="footer-link-svg">
              {soundStatus === 'active' ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                </svg>
              )}
            </div>
          </MagneticAnimation>
        </div>
      </div>
      <div onClick={() => soundStatus === 'active' ? turnOffSound() : turnOnSound() } className="footer-sound-svg">
        {soundStatus === 'active' ? (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
          </svg>
        )}
      </div>
    </>
  )
}
