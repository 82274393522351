import { useGLTF } from '@react-three/drei'
import React from 'react'
import Mesh from './Mesh.jsx'

export default function Ramen({ mouse }) {
  const { nodes} = useGLTF('/models/ramen_bowl.glb')
  const position = window.innerWidth <= 768 ? [-1, 0, 4] : [-3, -1, 4]
  const scale = window.innerWidth <= 768 ? 0.2 : 0.35

  return (
    <group rotation={[-Math.PI / 2, 0.5, 0]} scale={scale} position={position}>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_1} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Surface2} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Surface} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_2} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_3} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_4} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_5} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.BubbleWhite} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.JambonIn} />x
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.JambonOut} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.JambonIn_1} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.JambonOut_1} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_6} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_1} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut_1} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_7} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_2} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut_2} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_8} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_3} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut_3} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_9} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_4} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut_4} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_10} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_5} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut_5} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_11} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_6} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_6} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut_6} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_12} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_7} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut_7} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_13} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionIn_8} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OnionOut_8} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Wood_14} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Surface_1} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Surface_2} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.Surface_3} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.BowlDecoBlue} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.BowlDecoRed} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.OuterBowl} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.WhiteBowl} />
        <Mesh multiplier={1.4} mouse={mouse} node={nodes.WhiteBowl_1} />
      </group>
    </group>
  )
}

useGLTF.preload('/models/ramen_bowl.glb')
