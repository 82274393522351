import { useEffect, useState } from 'react'
import * as THREE from 'three'

const useDisplacement = (scene) => {
  const [loading, setLoading] = useState(true)
  const [displacement, setDisplacement] = useState(null)

  useEffect(() => {
    if (!scene) return

    const canvas = document.createElement('canvas')
    canvas.width = 128
    canvas.height = 128
    canvas.style.cssText = `
      position: fixed;
      width: 256px;
      height: 256px;
      top: 0;
      left: 0;
      z-index: 10;
      display: none;
    `

    const context = canvas.getContext('2d')
    context.fillRect(0, 0, canvas.width, canvas.height)

    const glowImage = new Image()
    glowImage.src = '../images/glow.png'

    const interactivePlane = new THREE.Mesh(
      new THREE.PlaneGeometry(10, 10),
      new THREE.MeshBasicMaterial({ color: 'red' })
    )
    interactivePlane.visible = false

    const raycaster = new THREE.Raycaster()
    const screenCursor = new THREE.Vector2(9999, 9999)
    const canvasCursor = new THREE.Vector2(9999, 9999)
    const canvasCursorPrevious = new THREE.Vector2(9999, 9999)
    const texture = new THREE.CanvasTexture(canvas)

    setDisplacement({
      canvas,
      context,
      glowImage,
      interactivePlane,
      raycaster,
      screenCursor,
      canvasCursor,
      texture,
      canvasCursorPrevious
    })
    setLoading(false)
  }, [scene])

  useEffect(() => {
    if (!displacement) return

    const { canvas, interactivePlane, raycaster, screenCursor, canvasCursor } = displacement
    const skillsElement = document.querySelector('#skills')

    const handlePointerMove = (event) => {
      if (skillsElement) {
        const rect = skillsElement.getBoundingClientRect()
        screenCursor.x = ((event.clientX - rect.left) / rect.width) * 2 - 1
        screenCursor.y = -((event.clientY - rect.top) / rect.height) * 2 + 1
      }
    }

    if (skillsElement) {
      skillsElement.appendChild(canvas)
      if(window.innerWidth > 768) {
        window.addEventListener('pointermove', handlePointerMove)
      }
      scene.add(interactivePlane)
    }

    return () => {
      if (skillsElement) {
        skillsElement.removeChild(canvas)
      }
      if(window.innerWidth > 768) {
        window.removeEventListener('pointermove', handlePointerMove)
      }
      scene.remove(interactivePlane)
    }
  }, [displacement])

  return { loading, displacement }
}

export default useDisplacement
